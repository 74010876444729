document.addEventListener("DOMContentLoaded", function () {
  let rotation1 = 0;
  let rotation2 = 0;
  const coll1 = document.getElementsByClassName("rotate1");
  const coll2 = document.getElementsByClassName("rotate2");
  const rotateImages1 = document.getElementsByClassName("rotate-image1");
  const rotateImages2 = document.getElementsByClassName("rotate-image2");

  for (let i = 0; i < coll1.length; i++) {
    coll1[i].addEventListener("click", function () {
      this.classList.toggle("active");
      const content = this.querySelector("p");
      if (content.style.display === "none") {
        content.style.display = "block";
      } else {
        content.style.display = "none";
      }
      rotation1 += 180;
      for (let j = 0; j < rotateImages1.length; j++) {
        // rotation += 180;
        rotateImages1[j].style.transform = `rotate(${rotation1}deg)`;
      }
    });
  }

  for (let i = 0; i < coll2.length; i++) {
    coll2[i].addEventListener("click", function () {
      this.classList.toggle("active");
      const content = this.querySelector("p");
      if (content.style.display === "none") {
        content.style.display = "block";
      } else {
        content.style.display = "none";
      }
      rotation2 += 180;
      for (let j = 0; j < rotateImages2.length; j++) {
        // rotation += 180;
        rotateImages2[j].style.transform = `rotate(${rotation2}deg)`;
      }
    });
  }
});
